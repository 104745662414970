import Model from "../Model";
import Collection from "./Collection";

export default class ModelCollection<T> extends Collection
{
    constructor(items: Model[] = []) {
        super(items)
    }

    clone(items: Model[]){
        return new ModelCollection(items)
    }
}
