import ModelCollection from "../../../../../../resources/js/infrastructure/Collection/ModelCollection";
import { httpClient } from "../../../../../../resources/js/services/HttpClient";
import CustomField from "../models/CustomField";

export default {
    namespaced: true,
    state: {
        items: new ModelCollection()
    },
    getters: {
        collection: (state) => (parameter) => {
            let collection = state.items;

            if (parameter?.models) {
                collection = state.items.whereIn('model', parameter.models)
            }

            if (parameter?.types) {
                collection = state.items.whereIn('type', parameter.types)
            }

            return collection
        }
    },
    actions: {
        async fetch({commit}, params = {limit: 0}) {
            await httpClient.get('/customization/custom-fields', params).then((response) => {
                let items = response.data.data.map((item) => new CustomField(item));

                commit('SET_ITEMS', new ModelCollection(items));
            });
        },
        async create({commit}, params) {
            await httpClient.post('/customization/custom-fields', params).then((response) => {
                commit('CREATE_ITEM', new CustomField(response.data.data));
            });
        },
        async update({commit}, {uuid, params}) {
            await httpClient.patch(`/customization/custom-fields/${uuid}`, params).then((response) => {
                commit('DELETE_ITEM', uuid);
                commit('CREATE_ITEM', new CustomField(response.data.data));
            });
        },
        async delete({commit}, uuid) {
            await httpClient.delete(`/customization/custom-fields/${uuid}`).then(() => {
                commit('DELETE_ITEM', uuid);
            });
        }
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        CREATE_ITEM(state, item) {
            let customField = new CustomField(item)

            state.items.push(customField);
        },
        DELETE_ITEM(state, uuid) {
            state.items = state.items.whereNot('uuid', uuid)
        }
    }
}
