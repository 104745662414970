import Cookies from 'js-cookie';

const defaultOptions = {
    domain: process.env.MIX_APP_DOMAIN
}

export function setCookie(name: string, value: object) {
    Cookies.set(name, JSON.stringify(value), defaultOptions);
}

export function getCookie(name: string): object | undefined {
    const cookie = Cookies.get(name);
    return cookie ? JSON.parse(cookie) : undefined;
}

export function removeCookie(name: string) {
    Cookies.remove(name, defaultOptions);
}
