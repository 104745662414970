import { httpClient } from "../../../../../../resources/js/services/HttpClient"

export async function findProductItem(uuid: string) {
    let response = await httpClient.get(`/catalog/product-items/${uuid}`).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function createProductItem(params: object) {
    let response = await httpClient.post(`/catalog/product-items`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function updateProductItem(uuid: string, params: object) {
    let response = await httpClient.patch(`/catalog/product-items/${uuid}`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function deleteProductItem(uuid: string) {
    let response = await httpClient.delete(`/catalog/product-items/${uuid}`).then((response) => {
        return response.data
    });

    return response;
}
