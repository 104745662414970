import ModelCollection from "../../../../../../resources/js/infrastructure/Collection/ModelCollection";
import { httpClient } from "../../../../../../resources/js/services/HttpClient";
import PriceTemplate from "../models/PriceTemplate";

export default {
    namespaced: true,
    state: {
        items: new ModelCollection()
    },
    getters: {
        collection: (state) => (params) => {
            let collection = state.items;

            if (params?.orders) {
                collection.orders(params.orders)
            }

            return collection
        }
    },
    actions: {
        async fetch({commit}) {
            await httpClient.get('/customization/price-templates').then((response) => {
                commit('SET_ITEMS', response.data.data);
            });
        },
        async create({commit}, params) {
            await httpClient.post('/customization/price-templates', params).then((response) => {
                commit('CREATE_ITEM', response.data.data);
            });
        },
        async update({commit}, {uuid, params}) {
            await httpClient.patch(`/customization/price-templates/${uuid}`, params).then((response) => {
                commit('DELETE_ITEM', uuid);
                commit('CREATE_ITEM', response.data.data);
            });
        },
        async delete({commit}, uuid) {
            await httpClient.delete(`/customization/price-templates/${uuid}`).then(() => {
                commit('DELETE_ITEM', uuid);
            });
        },
    },
    mutations: {
        SET_ITEMS(state, items) {
            items = items.map((item) => new PriceTemplate(item));

            state.items = new ModelCollection(items);
        },
        CREATE_ITEM(state, item) {
            let priceTemplate = new PriceTemplate(item)

            state.items.push(priceTemplate);
        },
        DELETE_ITEM(state, uuid) {
            state.items = state.items.whereNot('uuid', uuid)
        }
    }
}
