import Model from '../../../../../../resources/js/infrastructure/Model'
export default class Client extends Model {
    id: number | null
    uuid: string | null
    projectId: number | null
    name: string
    phone: string
    email: string
    customFields: object
    createdAt: Date | null
    updatedAt: Date | null

    constructor(data: any) {
        super()
        this.id = data?.id ?? null
        this.uuid = data?.uuid ?? null
        this.projectId = data?.project_id ?? null
        this.name = data?.name ?? ''
        this.phone = data?.phone ?? ''
        this.email = data?.email ?? ''
        this.customFields = data?.custom_fields ?? {}
        this.createdAt = data?.created_at ? new Date(data.created_at) : null
        this.updatedAt = data?.updated_at ? new Date(data.updated_at) : null
    }
}