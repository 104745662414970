import Noty from './../../../public/limitless/global_assets/js/plugins/notifications/noty.min.js'

export default class MessageNotificator {

    #show(message, type, buttons = false) {
        return new Noty({
            theme: 'limitless',
            layout: type === 'info' ? "bottomRight" : "topRight",
            text: message,
            type: type,
            modal: !!buttons,
            timeout: buttons ? false : 2500,
            buttons: buttons ? buttons : false
        }).show();
    }

    confirm(message, callBack) {
        let noty = this.#show(message, 'confirm', [
            Noty.button(`${translate('buttons.close')}`, 'btn btn-link', function () {
                noty.close();
            }),

            Noty.button(`${translate('buttons.confirm')}`, 'btn bg-teal ml-1', function () {
                callBack()

                noty.close()
            })
        ])
    }

    success(message) {
        this.#show(message, 'success')
    }

    error(message) {
        this.#show(message, 'error')
    }

    warning(message) {
        this.#show(message, 'warning')
    }

    information(message) {
        this.#show(message, 'info')
    }

    alert(message) {
        this.#show(message, 'alert')
    }
}
