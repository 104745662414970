import { httpClient } from "../../../../../../resources/js/services/HttpClient";
import Client from "../models/Client";

export default {
    namespaced: true,
    state: {
        client: new Client()
    },
    getters: {
        client(state) {
            return state.client;
        }
    },
    actions: {
        async fetch({ commit }, uuid) {
            await httpClient.get(`/sales/clients/${uuid}`).then((response) => {
                commit('SET_CLIENT', new Client(response.data.data));
            });
        },
        async create({ commit, state }) {
            let responseData = null,
                customFields = Object.entries(state.client.customFields);

            await httpClient.post('/sales/clients', {
                name: state.client.name,
                email: state.client.email,
                phone: state.client.phone,
                custom_fields: customFields.length ? customFields.map(([key, value]) => {
                    return {uuid: key, value: value};
                }) : null
            }).then((response) => {
                commit('SET_CLIENT', new Client(response.data.data));
                responseData = response.data;
            });

            return responseData;
        },
        async update({ commit, state }) {
            let responseData = null,
                customFields = Object.entries(state.client.customFields);

            await httpClient.patch(`/sales/clients/${state.client.uuid}`, {
                name: state.client.name,
                email: state.client.email,
                phone: state.client.phone,
                custom_fields: customFields.length ? customFields.map(([key, value]) => {
                    return {uuid: key, value: value};
                }) : null
            }).then((response) => {
                commit('SET_CLIENT', new Client(response.data.data));
                responseData = response.data;
            });

            return responseData;
        },
        async delete({ commit, state }) {
            let responseData = null;

            await httpClient.delete(`/sales/clients/${state.client.uuid}`).then((response) => {
                responseData = response.data;
            });

            return responseData;
        }
    },
    mutations: {
        SET_CLIENT(state, item) {
            state.client = item;
        }
    }
}
