import Category from "../models/Category";
import ModelCollection from "../../../../../../resources/js/infrastructure/Collection/ModelCollection";
import { httpClient } from "../../../../../../resources/js/services/HttpClient";
export default {
    namespaced: true,
    state: {
        categories: new ModelCollection()
    },
    getters: {
        collection(state) {
            return state.categories;
        },
        categoriesOption(state) {
            return state.categories.toArray().map((category) => {
                return {
                    key: category.uuid,
                    value: category.name
                }
            });
        },
        getCategoriesByUuid(state) {
            let array = state.categories.toArray().reduce((acc, category) => {
                acc[category.uuid] = category;
                return acc;
            }, {});
            return array
        }
    },
    actions: {
        async fetch({commit}, options = {limit: 0}) {
            await httpClient.get('/catalog/categories', options).then((response) => {
                let items = response.data.data.map((item) => new Category(item));
                commit('SET_CATEGORIES', new ModelCollection(items));
            })
        }
    },
    mutations: {
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        }
    }
}
