import Model from '../../../../../../resources/js/infrastructure/Model'
export default class ArrivalDeclarationStatus extends Model {
    uuid: string | null
    name: string
    type: string
    color: string
    order: number

    constructor(data: any) {
        super()
        this.uuid = data?.uuid ?? null
        this.name = data?.name ?? ''
        this.type = data?.type ?? ''
        this.color = data?.color ?? ''
        this.order = data?.order ?? 0
    }
}