
export default class PriceTemplate {
    id: bigint | null
    uuid: string | null
    name: string
    filled: number
    formula: string | null
    backgroundTasks: string[]
    createdAt: Date|null
    rounding: boolean

    constructor(priceTemplate: any) {
        this.id = priceTemplate?.id ?? null;
        this.uuid = priceTemplate?.uuid ?? null;
        this.name = priceTemplate?.name ?? '';
        this.filled = priceTemplate?.filled ?? 0;
        this.backgroundTasks = priceTemplate?.background_tasks ?? [];
        this.createdAt = priceTemplate.created_at ? new Date(priceTemplate.created_at) : null
        this.rounding = priceTemplate?.rounding ?? false

        if (priceTemplate?.formula) {
            let symbols = ['+', '-', '*', '/'];

            symbols.map((symbol) => priceTemplate.formula = priceTemplate.formula.replaceAll(symbol, `${symbol}`))

            this.formula = priceTemplate.formula;
        } else {
            this.formula = ''
        }
    }

    hasBackgroundTask(backgroundTask: string): boolean
    {
        return this.backgroundTasks.includes(backgroundTask)
    }

    addBackgroundTask(backgroundTask: string)
    {
        this.backgroundTasks.push(backgroundTask)
    }

    hasFormula()
    {
        return this.formula !== null && this.formula.length > 0;
    }
}
