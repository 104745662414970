import Model from '../../../../../../resources/js/infrastructure/Model'
export default class ProductItem extends Model {
    id: number | null
    uuid: string | null
    mainImageUuid: string | null
    name: string
    names: object
    description: string
    descriptions: object
    seo: string
    seos: object
    parentUuid: string | null
    sku: string
    automaticArrival: boolean
    priceTemplates: object
    customFields: object
    categoryUuid: string | null
    files: object
    manufacturer: string
    measurement: string


    constructor(data: any) {
        super()
        this.id = data?.id ?? null
        this.uuid = data?.uuid ?? null
        this.mainImageUuid = data?.main_image_uuid ?? null
        this.name = data?.name ?? ''
        this.names = data?.names ?? {}
        this.description = data?.description ?? ''
        this.descriptions = data?.descriptions ?? {}
        this.sku = data?.sku ?? ''
        this.automaticArrival = data?.automatic_arrival ?? false
        this.priceTemplates = data?.price_templates ?? {}
        this.customFields = data?.custom_fields ?? {}
        this.categoryUuid = data?.category_uuid ?? null
        this.files = data?.files ?? {}
        this.manufacturer = data?.manufacturer ?? ''
        this.measurement = data?.measurement ?? 'pieces'
        this.seo = data?.seo ?? ''
        this.seos = data?.seos ?? {}
        this.parentUuid = data?.parent_uuid ?? null
    }
}
