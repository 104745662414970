import Model from "../infrastructure/Model";
import ModelCollection from "../infrastructure/Collection/ModelCollection";
import ProjectCurrency from "./ProjectCurrency";
export default class Project extends Model
{
    id: bigint | null
    uuid: string | null
    name: string
    backgroundTasks: string[]
    languages: Array<object>
    mainLanguage: object
    currencies: ModelCollection<ProjectCurrency>
    alias: string
    isSupplier: number | null

    constructor(project) {
        super();

        this.id = project?.id ?? null;
        this.uuid = project?.uuid ?? null;
        this.name = project?.name ?? '';
        this.backgroundTasks = project?.background_tasks ?? [];
        this.languages = project?.languages ?? [];
        this.alias = project?.alias ?? '';
        this.mainLanguage = {};
        this.isSupplier = project?.is_supplier ?? null;
        if (this.languages.length > 0) {
            project.languages.forEach((language: any) => {
                if (language.pivot.main === 1) {
                    this.mainLanguage = language;
                }
            });
        }
        this.currencies = new ModelCollection<ProjectCurrency>(
            (project?.currencies || []).map((currency: any) => new ProjectCurrency(currency))
        )
    }

    hasBackgroundTask(backgroundTask: string): boolean
    {
        return this.backgroundTasks.includes(backgroundTask)
    }

    addBackgroundTask(backgroundTask: string)
    {
        this.backgroundTasks.push(backgroundTask)
    }
}
