import Category from "../models/Category";
import { httpClient } from "../../../../../../resources/js/services/HttpClient";
export default {
    namespaced: true,
    state: {
        category: new Category()
    },
    getters: {
        category(state) {
            return state.category;
        }
    },
    actions: {
        async fetch({commit}, params) {
            await httpClient.get(`/catalog/categories/${params.uuid}`, {
                extra: params.extra
            }).then((response) => {
                commit('SET_CATEGORY', new Category(response.data.data))
            })
        },
        async create({commit, state}) {
            let responseData = null;

            await httpClient.post('/catalog/categories', {
                names: state.category.names,
                parent_uuid: state.category.parentUuid
            }).then((response) => {
                commit('SET_CATEGORY', new Category(response.data.data));
                responseData = response.data.data
            })

            return responseData;
        },
        async update({commit, state}) {
            let responseData = null;

            await httpClient.patch(`/catalog/categories/${state.category.uuid}`, {
                names: state.category.names,
                parent_uuid: state.category.parentUuid
            }).then((response) => {
                commit('SET_CATEGORY', new Category(response.data.data));
                responseData = response.data.data
            })

            return responseData;
        },
        async delete({state}) {
            let responseData = null;

            await httpClient.delete(`/catalog/categories/${state.category.uuid}`).then(() => {
                responseData = [];
            });

            return responseData;
        }
    },
    mutations: {
        SET_CATEGORY(state, category) {
            state.category = category;
        }
    }
}
