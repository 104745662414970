import ProductItem from "../models/ProductItem";
import ModelCollection from "../../../../../../resources/js/infrastructure/Collection/ModelCollection";
import ProductItemFile from "../models/ProductItemFile";
import Category from "../models/Category";
import Filter from "../models/Filter";
import {createProductItem, deleteProductItem, findProductItem, updateProductItem} from "../api/productItem";
import {findFileRelations} from "../api/file";
import FilterItem from "../models/FilterItem";
import {httpClient} from "../../../../../../resources/js/services/HttpClient";

export default {
    namespaced: true,
    state: {
        productItem: new ProductItem(),
        parent: new ProductItem(),
        category: new Category(),
        files: new ModelCollection(),
        filters: [],
        availability: {}
    },
    getters: {
        productItem(state) {
            return state.productItem
        },
        parent(state) {
            return state.parent
        },
        category(state) {
            return state.category
        },
        filesCollection: (state) => (params) => {
            let collection = state.files

            if (params?.orders) {
                collection.orders(params.orders)
            }

            return collection
        },
        filters(state) {
            return state.filters
        },
        availability(state) {
            return state.availability
        },
    },
    actions: {
        async fetch({ commit }, { uuid }) {
            let productItemData = await findProductItem(uuid);

            if (productItemData) {
                let productItem = new ProductItem(productItemData)

                commit('SET_PRODUCT_ITEM', productItem)
                commit('SET_PARENT', new ProductItem(productItemData.parent))
                commit('SET_CATEGORY', new Category(productItemData.category))
                commit('SET_FILTERS', productItemData?.filters.map((filter) => ({
                    filter: new Filter(filter),
                    filterItem: new FilterItem(filter.filter_item)
                })))
                commit('SET_AVAILABILITY', productItemData.availability)
            }
        },
        async create({ dispatch }, isDuplicate) {
            let params = await dispatch('makeRequest', {action: 'create', isDuplicate: isDuplicate});

            return createProductItem(params);
        },
        async update({ state, dispatch, commit }) {
            let params = await dispatch('makeRequest', {action: 'update'}),
                productItem = await updateProductItem(state.productItem.uuid, params);

            commit('SET_PRODUCT_ITEM', new ProductItem(productItem))

            if (productItem) {
                await dispatch('fetchRelations')
            }

            return productItem;
        },
        async delete({ state }) {
            return deleteProductItem(state.productItem.uuid);
        },
        async createProductItemFiles({ state, commit }, files) {
            let responseData = [];

            let uploadPromises = Array.from(files).map((file) => {
                let requestFile = new FormData();
                requestFile.append('file', file);
                requestFile.append('type', 'product-item');
                return httpClient.post('/file/files', requestFile);
            });

            let responses = await Promise.all(uploadPromises);
            responseData = responses.map(response => response.data.data);

            let responseFiles = state.files

            responseFiles.merge(new ModelCollection(responseData.map((file) => new ProductItemFile({
                file: file
            }))))

            commit('SET_FILES', responseFiles)
        },
        async fetchRelations({ state, commit }) {
            let relations = await findFileRelations({
                model_types: ['product_item'],
                model_uuids: [state.productItem.uuid],
                limit: 0
            });

            relations.sort((a, b) => {
                if (a.uuid === state.productItem.mainImageUuid) {
                    return -1;
                } else if (b.uuid === state.productItem.mainImageUuid) {
                    return 1;
                } else {
                    return new Date(a.created_at) - new Date(b.created_at);
                }
            });

            commit('SET_FILES', new ModelCollection(relations.map((file) => new ProductItemFile(file))))
        },
        deleteFile({ state, commit }, index) {
            let files = state.files.toArray();

            files.splice(index, 1)

            commit('SET_FILES', new ModelCollection(files))
        },
        async makeRequest({ state, rootGetters }, {action, isDuplicate}) {
            let existsCustomFieldUuids = rootGetters['customFields/collection']().toArray().map((priceTemplate) => priceTemplate.uuid),
                existsPriceTemplateUuids = rootGetters['priceTemplates/collection']().toArray().map((customField) => customField.uuid),
                files = state.files.toArray(),
                request = {
                    name: state.productItem.name,
                    names: state.productItem.names,
                    category_uuid: state.productItem.categoryUuid,
                    automatic_arrival: state.productItem.automaticArrival,
                    manufacturer: state.productItem.manufacturer,
                    measurement: state.productItem.measurement,
                    seos: state.productItem.seos,
                    parent_uuid: state.productItem.parentUuid,
                    filter_item_uuids: state.filters.map(item => item.filterItem.uuid),
                    custom_fields: Object.entries(state.productItem.customFields)
                        .filter(([uuid, value]) => existsCustomFieldUuids.includes(uuid)
                            && value != '')
                        .map(([uuid, value]) => ({
                            uuid,
                            value
                        })),
                    price_templates: Object.entries(state.productItem.priceTemplates)
                        .filter(([uuid, value]) => existsPriceTemplateUuids.includes(uuid)
                            && value != '')
                        .map(([uuid, value]) => ({
                            uuid,
                            value
                        }))
                };

            if (isDuplicate) {
                const timestamp = Date.now().toString();
                const lastThreeDigits = timestamp.slice(-3);

                request.sku = state.productItem.sku + '-DUPLICATE-' + lastThreeDigits;
            } else {
                request.sku = state.productItem.sku
            }

            request.files = files.map(file => {
                if (file.relationUuid && action === 'update') {
                    return {
                        relationship_uuid: file.relationUuid
                    }
                } else if (file.fileUuid) {
                    return {
                        file_uuid: file.fileUuid
                    }
                }
            }).filter(Boolean)

            if (Object.keys(state.productItem.descriptions).length > 0) {
                request.descriptions = state.productItem.descriptions
            }

            return request
        },
        setParent({ commit }, productData) {
            let parent = new ProductItem(productData)

            commit('SET_PARENT_UUID', parent.uuid)
            commit('SET_PARENT', parent)
            commit('SET_CATEGORY_UUID', parent.categoryUuid)
            commit('SET_CATEGORY', new Category(productData?.category ?? null))
        },
        setCategory({ commit }, categoryData) {
            let category = new Category(categoryData)

            commit('SET_CATEGORY_UUID', category.uuid)
            commit('SET_CATEGORY', category)
        },
        addFilter({ state, commit }, { filter, filterItem }) {
            let filters = state.filters;

            filters.push({
                filter: new Filter(filter),
                filterItem: new FilterItem(filterItem)
            })
            commit('SET_FILTERS', filters)
        },
        removeFilter({ state, commit }, index) {
            let filters = state.filters

            filters.splice(index, 1)

            commit('SET_FILTERS', filters)
        },
        setMainFile({ state, commit }, index) {
            let files = state.files.toArray(),
                mainFile = files[index]

            files.splice(index, 1)
            files.unshift(mainFile)

            commit('SET_FILES', new ModelCollection(files))
        }
    },
    mutations: {
        SET_PRODUCT_ITEM(state, productItem) {
            state.productItem = productItem
        },
        SET_PARENT_UUID(state, parentUuid) {
            state.productItem.parentUuid = parentUuid
        },
        SET_FILES(state, files) {
            state.files = files
        },
        SET_PARENT(state, parent) {
            state.parent = parent
            if (parent.uuid != null) {
                state.productItem.measurement = parent.measurement
                state.productItem.manufacturer = parent.manufacturer
            }
        },
        SET_CATEGORY(state, category) {
            state.category = category
        },
        SET_CATEGORY_UUID(state, categoryUuid) {
            state.productItem.categoryUuid = categoryUuid
        },
        SET_FILTERS(state, filters) {
            state.filters = filters
        },
        SET_AVAILABILITY(state, availability) {
            state.availability = availability
        }
    }
}
