export default class Collection
{
    _items: object[]

    constructor(items: object[] = []) {
        this._items = items;
    }

    push(item: object): void
    {
        this._items.push(item)
    }

    whereNot(field: string, value: any)
    {
        let items = this._items.filter((item) => item[field] != value)

        return this.clone(items)
    }

    whereIn(field: string, values: any[])
    {
        let items = this._items.filter((item) => values.includes(item[field]))

        return this.clone(items)
    }

    orders(orders: object[])
    {
        let items = this._items;

        if (orders.length > 0) {
            items.sort((a: any, b: any) => this.sort(a, b, orders));
        }

        return this.clone(items)
    }

    sort(a: any, b: any, orders: any[], index: number = 0)
    {
        let result = 0;

        let order = orders[index];

        if (order) {
            if (a[order.column] instanceof Date && b[order.column] instanceof Date) {
                result = a[order.column]?.getTime() - b[order.column]?.getTime()
            } else if (a[order.column] instanceof String && b[order.column] instanceof String) {
                result = a[order.column].localeCompare(b[order.column])
            } else if (a[order.column] instanceof Boolean && b[order.column] instanceof Boolean) {
                result = a[order.column] === b[order.column] ? 0 : a[order.column] ? 1 : -1
            }

            if (order.dir !== 'asc') {
                result = -result;
            }
        }

        return result === 0 && orders[index + 1]
            ? this.sort(a, b, orders, index + 1)
            : result;
    }

    transform(callback: (value: object) => object)
    {
        let items = this._items.map((item) => callback(item));

        return this.clone(items)
    }

    map(callback: (value: object) => void)
    {
        this._items.map((item) => callback(item));
    }

    merge(collection)
    {
        this._items = this.toArray().concat(collection.toArray())
    }

    toArray()
    {
        return this._items
    }

    clone(items: object[]){
        return new Collection(items)
    }
}
