import { httpClient } from '../../../../../../resources/js/services/HttpClient';
import ExitDeclarationStatus from '../models/ExitDeclarationStatus';
export default {
    namespaced: true,
    state: {
        exitDeclarationStatus: new ExitDeclarationStatus()
    },
    getters: {
        exitDeclarationStatus(state) {
            return state.exitDeclarationStatus;
        }
    },
    actions: {
        async fetch({ commit }, uuid) {
            await httpClient.get(`/management/exit-declaration-statuses/${uuid}`).then((response) => {
                commit('SET_EXIT_DECLARATION_STATUS', new ExitDeclarationStatus(response.data.data));
            });
        },
        async create({ commit, state }) {
            let responseData = null;

            await httpClient.post('/management/exit-declaration-statuses', {
                name: state.exitDeclarationStatus.name,
                color: state.exitDeclarationStatus.color,
                type: state.exitDeclarationStatus.type,
                order: state.exitDeclarationStatus.order
            }).then((response) => {
                commit('SET_EXIT_DECLARATION_STATUS', new ExitDeclarationStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async update({ commit, state }) {
            let responseData = null;

            await httpClient.put(`/management/exit-declaration-statuses/${state.exitDeclarationStatus.uuid}`, {
                name: state.exitDeclarationStatus.name,
                color: state.exitDeclarationStatus.color,
                type: state.exitDeclarationStatus.type,
                order: state.exitDeclarationStatus.order
            }).then((response) => {
                commit('SET_EXIT_DECLARATION_STATUS', new ExitDeclarationStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async delete({ commit, state }) {
            let responseData = null;

            await httpClient.delete(`/management/exit-declaration-statuses/${state.exitDeclarationStatus.uuid}`).then(() => {
                commit('SET_EXIT_DECLARATION_STATUS', new ExitDeclarationStatus());
                responseData = [];
            });
            
            return responseData;
        }
    },
    mutations: {
        SET_EXIT_DECLARATION_STATUS(state, exitDeclarationStatus) {
            state.exitDeclarationStatus = exitDeclarationStatus;
        }
    }
}