import Project from "../../models/Project";
import { authorizeUser, createProject } from "../api/auth";
import { httpClient } from "../../services/HttpClient";

export default {
    namespaced: true,
    state: {
        project: new Project(),
    },
    getters: {
        project: state => state.project,
    },
    actions: {
        async authorize({commit, dispatch}, {email, password, remember}) {
            let responseData = await authorizeUser({email, password, remember});

            if (responseData) {
                commit('SET_PROJECTS', responseData.projects);
                await dispatch('fetchToken', {email, password});
            }

            return responseData;
        },
        async fetch({commit}) {
            await httpClient.get('/system/auth').then((response) => {
                commit('SET_PROJECT', new Project(response.data.data.project));
            });
        },
        async createProject({commit}, params) {
            let responseData = await createProject(params);
            
            if (responseData) {
                commit('SET_PROJECTS', responseData.projects);
            }

            return responseData;
        },
        async addBackgroundTask({commit, state}, backgroundTask)
        {
            await httpClient.patch('/system/project', {
                background_tasks: [backgroundTask]
            }).then(() => {
                state.project.addBackgroundTask(backgroundTask)
            });
        },
        async fetchToken({commit}, {email, password}) {
            await httpClient.post('/api/login', {
                login: email,
                password
            }).then((response) => {
                commit('SET_TOKEN', response.data.data);
            })
        }
    },
    mutations: {
        SET_PROJECT(state, project) {
            state.project = project;
        }
    }
}
