import { httpClient } from "../../../../../../resources/js/services/HttpClient";

export async function findWarehouse(uuid: string) {
    let response = await httpClient.get(`/management/warehouses/${uuid}`).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function createWarehouse(params: object) {
    let response = await httpClient.post(`/management/warehouses`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function updateWarehouse(uuid: string, params: object) {
    let response = await httpClient.patch(`/management/warehouses/${uuid}`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function deleteWarehouse(uuid: string) {
    let response = await httpClient.delete(`/management/warehouses/${uuid}`).then((response) => {
        return response.data
    });

    return response;
}