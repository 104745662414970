import Supplier from "../models/Supplier";
import { findSupplier, updateSupplier, deleteSupplier } from "../api/supplier";

export default {
    namespaced: true,
    state: {
        supplier: new Supplier()
    },
    getters: {
        supplier(state) {
            return state.supplier;
        }
    },
    actions: {
        async fetch({ commit }, { uuid }) {
            let supplierData = await findSupplier(uuid);

            if (supplierData) {
                let supplier = new Supplier(supplierData);

                commit('SET_SUPPLIER', supplier);
            }
        },
        async update({ dispatch, state, commit }) {
            let request = await dispatch('makeRequest'),
                supplier = await updateSupplier(state.supplier.uuid, request);
            
            commit('SET_SUPPLIER', supplier);

            return supplier;
        },
        async delete({ state}) {
            return deleteSupplier(state.supplier.uuid);
        },
        makeRequest({ state }) {
            let request = {
                name: state.supplier.name
            }

            return request;
        }
    },
    mutations: {
        SET_SUPPLIER(state, supplier) {
            state.supplier = supplier;
        }
    }
}