import Model from '../../../../../../resources/js/infrastructure/Model'

export default class Warehouse extends Model {
    id: number | null
    uuid: string | null
    name: string
    created_at: Date | null

    constructor(data: any) {
        super()
        this.id = data?.id ?? null
        this.uuid = data?.uuid ?? null
        this.name = data?.name ?? ''
        this.created_at = data?.created_at ? new Date(data.created_at) : null
    }
}