export function generateUrl(path: string, subdomain: string = null) {
    let protocol = match(process.env.MIX_APP_ENV, {
        'local' : 'http',
        'production' : 'https',
    });

    let domain = match(process.env.MIX_APP_ENV, {
        'local' : 'fasunok.loc',
        'production' : 'fasunok.com',
    });

    if (subdomain) {
        domain = `${subdomain}.${domain}`;
    }

    return `${protocol}://${domain}/${path}`;
}

export function match<T, R>(value: T, patterns: { [key: string]: R | ((v: T) => R) }): R {
    if (value in patterns) {
        const result = patterns[value];
        return typeof result === 'function' ? (result as Function)(value) : result;
    }

    throw new Error(`No match found for value: ${value}`);
}

export function chunk<T>(array: T[], chunkSize: number): T[][] {
    if (chunkSize <= 0) {
        throw new Error("Chunk size must be greater than 0");
    }

    const chunks: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}
