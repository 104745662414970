import ModelCollection from "../../../../../../resources/js/infrastructure/Collection/ModelCollection";
import Filter from "../models/Filter";
import { httpClient } from "../../../../../../resources/js/services/HttpClient";
export default {
    namespaced: true,
    state: {
        filters: new ModelCollection()
    },
    getters: {
        collection(state) {
            return state.filters;
        }
    },
    actions: {
        async fetch({commit}, options = {limit: 0, extra: 1}) {
            await httpClient.get('/catalog/filters', options).then((response) => {
                let items = response.data.data;
                items.sort((a, b) => a.name.localeCompare(b.name))
                items.forEach((filter) => filter.items.sort((a, b) => a.name.localeCompare(b.name)))
                items = items.map((item) => new Filter(item));
                commit('SET_FILTERS', new ModelCollection(items));
            })
        }
    },
    mutations: {
        SET_FILTERS(state, filters) {
            state.filters = filters;
        }
    }
}