require('./bootstrap');

import {createApp, defineAsyncComponent} from 'vue'
import store from './store/index'

// import CKEditor from '@ckeditor/ckeditor5-vue2';
import VTooltip from 'v-tooltip';
import Sortable from 'vue-sortable'
import VueTippy from 'vue-tippy'
import CKEditor from '@ckeditor/ckeditor5-vue';
import vClickOutside from "click-outside-vue3"
import mitt from 'mitt'
import * as Sentry from "@sentry/vue";

// If you want to add to window object
window.translate = require('./VueTranslation/Translation').default.translate;

// Vue.use(CKEditor);

// const app = createApp({})
// app.component('example-component', () => import('./components/ExampleComponent.vue')))
// const app = createApp({
const componentImports = {
    'notification-component': () => import('./components/NotificationComponent.vue'),

    'index-arrival-declaration-page' : () => import('../../app/Modules/Management/Resources/js/pages/ArrivalDeclaration/index/IndexArricalDeclarationPage.vue'),
    'edit-arrival-declaration-page' : () => import('../../app/Modules/Management/Resources/js/pages/ArrivalDeclaration/EditArrivalDeclarationPage.vue'),

    'index-exit-declaration-page' : () => import('../../app/Modules/Management/Resources/js/pages/ExitDeclaration/index/IndexExitDeclarationPage.vue'),
    'edit-exit-declaration-page' : () => import('../../app/Modules/Management/Resources/js/pages/ExitDeclaration/EditExitDeclarationPage.vue'),

    'index-inventory-page' : () => import('../../app/Modules/Management/Resources/js/pages/Inventory/index/IndexInventoryPage'),
    'edit-inventory-page' : () => import('../../app/Modules/Management/Resources/js/pages/Inventory/EditInventoryPage'),
    'create-inventory-page' : () => import('../../app/Modules/Management/Resources/js/pages/Inventory/CreateInventoryPage'),

    'create-user-page' : () => import('./pages/auth/registration/CreateUserPage'),
    'create-project-page' : () => import('./pages/auth/registration/CreateProjectPage'),

    'authentication-page' : () => import('./pages/auth/authentication/AuthenticationPage'),

    'basic-page' : () => import('./pages/settings/basic/BasicPage'),
    'index-custom-field-page': () => import('../../app/Modules/Customization/Resources/js/pages/CustomField/CustomFieldPage.vue'),

    'index-filter-page' : () => import('./pages/catalog/filter/IndexFilterPage.vue'),
    'create-filter-page' : () => import('./pages/catalog/filter/CreateFilterPage'),
    'edit-filter-page' : () => import('./pages/catalog/filter/EditFilterPage'),

    'index-category-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/category/IndexCategoryPage.vue'),
    'create-category-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/category/CreateCategoryPage.vue'),
    'edit-category-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/category/EditCategoryPage.vue'),

    'user-profile-page': () => import('./pages/user/UserProfilePage.vue'),

    'index-product-item-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/product-item/IndexProductItemPage.vue'),
    'create-product-item-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/product-item/CreateProductItemPage.vue'),
    'edit-product-item-page' : () => import('../../app/Modules/Catalog/Resources/js/pages/product-item/EditProductItemPage.vue'),

    'index-billing-page' : () => import('./pages/billing/IndexBillingPage.vue'),
    'index-analytics-page' : () => import('./pages/analytics/IndexAnalyticsPage.vue'),

    'integration-page' : () => import('../../app/Modules/Integration/Resources/js/pages/index/IntegrationIndexPage.vue'),
    'integration-edit-page' : () => import('../../app/Modules/Integration/Resources/js/pages/edit/IntegrationEditPage.vue'),
    'integration-create-page' : () => import('../../app/Modules/Integration/Resources/js/pages/IntegrationCreatePage.vue'),

    'integration-category-page' : () => import('../../app/Modules/Integration/Resources/js/pages/internal/InternalCategoryPage.vue'),
    'external-category-page' : () => import('../../app/Modules/Integration/Resources/js/pages/external/ExternalCategoryPage.vue'),
    'integration-index-page' : () => import('../../app/Modules/Integration/Resources/js/pages/index/IntegrationIndexPage.vue'),
    'integration-settings-page' : () => import('../../app/Modules/Integration/Resources/js/pages/IntegrationSettingsPage.vue'),

    'index-subscription-page' : () => import('./pages/settings/subscrition/IndexSubscriptionPage.vue'),

    'index-multilanguage-page' : () => import('../../app/Modules/Customization/Resources/js/pages/Multilanguage/MultiLanguagePage.vue'),

    'price-templates' : () => import('../../app/Modules/Customization/Resources/js/pages/PriceTemplate/PriceTemplatePage.vue'),

    'index-bank-detail-page' : () => import('../../app/Modules/System/Resources/js/pages/BankDetails/IndexBankDetailsPage.vue'),
    'create-bank-detail-page' : () => import('../../app/Modules/System/Resources/js/pages/BankDetails/CreateBankDetailsPage.vue'),
    'edit-bank-details-page' : () => import('../../app/Modules/System/Resources/js/pages/BankDetails/EditBankDetailsPage.vue'),

    'index-exit-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ExitDeclarationStatus/IndexExitDeclarationStatusPage.vue'),
    'create-exit-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ExitDeclarationStatus/CreateExitDeclarationStatusPage.vue'),
    'edit-exit-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ExitDeclarationStatus/EditExitDeclarationStatusPage.vue'),

    'index-arrival-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ArrivalDeclarationStatus/IndexArrivalDeclarationStatusPage.vue'),
    'create-arrival-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ArrivalDeclarationStatus/CreateArrivalDeclarationStatusPage.vue'),
    'edit-arrival-declaration-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/ArrivalDeclarationStatus/EditArrivalDeclarationStatusPage.vue'),

    'index-inventory-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/InventoryStatus/IndexInventoryStatusPage.vue'),
    'create-inventory-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/InventoryStatus/CreateInventoryStatusPage.vue'),
    'edit-inventory-status-page' : () => import('../../app/Modules/Management/Resources/js/pages/InventoryStatus/EditInventoryStatusPage.vue'),

    'index-warehouse-page' : () => import('../../app/Modules/Management/Resources/js/pages/Warehouse/IndexWarehousePage.vue'),
    'edit-warehouse-page' : () => import('../../app/Modules/Management/Resources/js/pages/Warehouse/EditWarehousePage.vue'),

    'index-supplier-page' : () => import('../../app/Modules/Management/Resources/js/pages/Supplier/IndexSupplierPage.vue'),
    'edit-supplier-page' : () => import('../../app/Modules/Management/Resources/js/pages/Supplier/EditSupplierPage.vue'),

    'index-turnover-page' : () => import('../../app/Modules/Analytics/Resources/js/pages/Turnover/IndexTurnoverPage.vue'),
    'exit-declaration-statuses-page' : () => import('../../app/Modules/Analytics/Resources/js/pages/ExitDeclarationStatuses/ExitDeclarationStatuses.vue'),
}

const app = createApp({
    components: Object.fromEntries(
        Object.entries(componentImports).map(([name, importFunc]) => [
            name,
            defineAsyncComponent(importFunc)
        ])
    )
});

if (process.env.MIX_APP_ENV === 'production') {
  Sentry.init({
    app,
    dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException;

      if (error && error.isAxiosError) {
          return null;
      }

      return event;
    }
  });
}

app.config.globalProperties.translate = require('./VueTranslation/Translation').default.translate;

app.use(store)

app.config.globalProperties.emitter = mitt()

app.use(VTooltip, {
    defaultClass: "fade show",
    disposeTimeout: 0
})
app.use(CKEditor)
app.use(Sortable)
app.use(vClickOutside)
app.use(
    VueTippy,
    // optional
    {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
      componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
      defaultProps: {
        placement: 'auto-end',
        allowHTML: true,
      }, // => Global default options * see all props
    }
  )

app.mount('#app')
