import Model from '../../../../../../resources/js/infrastructure/Model'
export default class BankDetail extends Model {
    id: number | null
    uuid: string | null
    projectUuid: string | null
    clientUuid: string | null
    name: string
    code: string
    iban: string
    bankName: string
    address: string
    phone: string
    email: string
    nameInDocument: string
    createdAt: Date | null
    updatedAt: Date | null

    constructor(data: any = {}) {
        super()
        this.id = data?.id || null
        this.uuid = data?.uuid || null
        this.projectUuid = data?.project_uuid || null
        this.clientUuid = data?.client_uuid || null
        this.name = data?.name || ''
        this.code = data?.code || ''
        this.iban = data?.iban || ''
        this.bankName = data?.bank_name || ''
        this.address = data?.address || ''
        this.phone = data?.phone || ''
        this.email = data?.email || ''
        this.nameInDocument = data?.name_in_document || ''
        this.createdAt = data?.created_at ? new Date(data.created_at) : null
        this.updatedAt = data?.updated_at ? new Date(data.updated_at) : null
    }
}