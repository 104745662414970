import model from '../../../../../../resources/js/infrastructure/Model'
export default class ProductItemFile extends model {
    relationUuid: string | null
    fileUuid: string | null
    url: string
    createdAt: Date

    constructor(data: any) {
        super()

        this.relationUuid = data?.uuid;
        this.fileUuid = null;
        this.url = data?.origin_url;
        this.createdAt = new Date(data.created_at);

        if (data?.file !== undefined && data?.file !== null) {
            this.fileUuid = data.file.uuid;
            this.url = data.file.url;
            this.createdAt = new Date(data.file.created_at)
        }
    }
}
