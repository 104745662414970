import { createStore } from 'vuex'

import AuthModule from './modules/auth.module'
import CustomFieldsModule from '../../../app/Modules/Customization/Resources/js/store/customFields.module'
import PriceTemplatesModule from '../../../app/Modules/Customization/Resources/js/store/priceTemplates.module'
import Client from '../../../app/Modules/Management/Resources/js/store/client.module'
import BankDetail from '../../../app/Modules/System/Resources/js/store/bankDetail.module'
import ExitDeclarationStatus from '../../../app/Modules/Management/Resources/js/store/exitDeclarationStatus.module'
import ArrivalDeclaraionStatus from '../../../app/Modules/Management/Resources/js/store/arrivalDeclarationStatus.module'
import Categories from '../../../app/Modules/Catalog/Resources/js/store/categories.module'
import InventoryStatus from '../../../app/Modules/Management/Resources/js/store/inventoryStatus.module'
import Category from '../../../app/Modules/Catalog/Resources/js/store/category.module'
import Filters from '../../../app/Modules/Catalog/Resources/js/store/filters.module'
import ProductItem from '../../../app/Modules/Catalog/Resources/js/store/productItem.module'
import Warehouse from '../../../app/Modules/Management/Resources/js/store/warehouse.module'
import Supplier from '../../../app/Modules/Management/Resources/js/store/supplier.module'
import createWebSocketPlugin from '../sockets'

const sockets = createWebSocketPlugin()
export default createStore({
    modules: {
        auth: AuthModule,
        customFields: CustomFieldsModule,
        priceTemplates: PriceTemplatesModule,
        client: Client,
        bankDetail: BankDetail,
        exitDeclarationStatus: ExitDeclarationStatus,
        categories: Categories,
        category: Category,
        filters: Filters,
        arrivalDeclarationStatus: ArrivalDeclaraionStatus,
        inventoryStatus: InventoryStatus,
        warehouse: Warehouse,
        productItem: ProductItem,
        supplier: Supplier
    },
    plugins: [sockets]
})
