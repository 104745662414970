import Echo from 'laravel-echo';

export default function createWebSocketPlugin () {
    return (store) => {
        window.Pusher = require('pusher-js');

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.MIX_PUSHER_APP_KEY,
            cluster: process.env.MIX_PUSHER_APP_CLUSTER,
            wsHost: window.location.hostname,
            wsPort: process.env.MIX_LARAVEL_WEBSOCKETS_PORT,
            forceTLS: false,
            disableStats: true
        });

        if (document.querySelector("meta[name='auth_project_id']") !== null) {
            let authProjectId = document.querySelector("meta[name='auth_project_id']").getAttribute('content');

            const chanel = window.Echo.private(`projects.${authProjectId}`);

            chanel.listen('\\App\\Modules\\Customization\\Events\\PriceTemplate\\PriceTemplateStoredEvent', function (event) {
                store.dispatch('priceTemplates/fetch')
            }).listen('\\App\\Modules\\Customization\\Events\\PriceTemplate\\PriceTemplateUpdatedEvent', function (event) {
                store.dispatch('priceTemplates/fetch')
            }).listen('\\App\\Modules\\Customization\\Events\\PriceTemplate\\PriceTemplateDestroyedEvent', function (event) {
                store.dispatch('priceTemplates/fetch')
            }).listen('\\App\\Modules\\Customization\\Events\\CustomField\\CustomFieldStoredEvent', function (event) {
                store.dispatch('customFields/fetch')
            }).listen('\\App\\Modules\\Customization\\Events\\CustomField\\CustomFieldUpdatedEvent', function (event) {
                store.dispatch('customFields/fetch')
            }).listen('\\App\\Modules\\Customization\\Events\\CustomField\\CustomFieldDestroyedEvent', function (event) {
                store.dispatch('customFields/fetch')
            }).listen('\\App\\Modules\\System\\Events\\Project\\ProjectUpdatedEvent', function (event) {
                store.dispatch('auth/fetch')
            })
        }
    }
}
