import { httpClient } from "../../services/HttpClient";

export async function authorizeUser(params: object) {
    let response = await httpClient.post(`/authentication/login`, params).then((response) => {
        return response
    });

    return response?.data?.data ?? null;
}

export async function createProject(params: object) {
    let response = await httpClient.post(`/registration/store-project`, params).then((response) => {
        return response
    });

    return response?.data?.data ?? null;
}