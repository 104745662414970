import Model from '../infrastructure/Model'
export default class ProjectCurrency extends Model {
    uuid: string | null
    name: string
    main: boolean
    rate: number
    code: string

    constructor(data: any = {}) {
        super()
        this.uuid = data?.uuid || null
        this.name = data?.name || ''
        this.main = data?.main || false
        this.rate = data?.rate || 0
        this.code = data?.code || ''
    }
}