import {generateUrl} from "../utils";

const { default: axios } = require("axios")
const qs = require('qs')
import MessageNotificator from "./MessageNotificator";

export class HttpClient {

    constructor() {
        this.notificator = new MessageNotificator()

        this.api = axios.create();

        this.api.interceptors.response.use(
            (response) => response,
            (error) => {
                let status = error?.response?.status;

                if (status) {
                    if (status === 401) {
                        window.location.href = generateUrl('authentication')
                    } else {
                        this.#showExceptions(error);
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    get(url: string, params?: object, withCredentials: boolean = false) {
        return this.api.get(url, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params)
            },
            withCredentials: withCredentials,
        });
    }

    post(url: string, params?: object) {
        return this.api.post(url, params);
    }

    patch(url: string, params: object) {
        return this.api.patch(url, params);
    }

    delete(url: string, params?: object) {
        return this.api.delete(url, {data: params});
    }

    put(url: string, params: object) {
        return this.api.put(url, params);
    }
    #showExceptions(error)
    {
        let response = error.response;

        let message = response?.data?.message;

        if (message === undefined) {
            return;
        }

        let errors = [];

        for (const [key, value] of Object.entries(response?.data?.errors ?? {})) {
            errors.push(value)
        }

        let html = document.createElement('div')

        if (errors.length > 0) {
            errors.forEach((item) => {
                let li = document.createElement("li");
                li.innerText = item;
                html.appendChild(li);
            });
        } else {
            let li = document.createElement("li");
            li.innerText = message;
            html.appendChild(li);
        }

        this.notificator.warning(html.outerHTML);
    }
}

export const httpClient = new HttpClient()
