import { httpClient } from '../../../../../../resources/js/services/HttpClient';
import InventoryStatus from '../models/InventoryStatus';
export default {
    namespaced: true,
    state: {
        inventoryStatus: new InventoryStatus()
    },
    getters: {
        inventoryStatus(state) {
            return state.inventoryStatus;
        }
    },
    actions: {
        async fetch({ commit }, uuid) {
            await httpClient.get(`/management/inventory-statuses/${uuid}`).then((response) => {
                commit('SET_INVENTORY_STATUS', new InventoryStatus(response.data.data));
            });
        },
        async create({ commit, state }) {
            let responseData = null;

            await httpClient.post('/management/inventory-statuses', {
                name: state.inventoryStatus.name,
                color: state.inventoryStatus.color,
                type: state.inventoryStatus.type,
                order: state.inventoryStatus.order
            }).then((response) => {
                commit('SET_INVENTORY_STATUS', new InventoryStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async update({ commit, state }) {
            let responseData = null;

            await httpClient.put(`/management/inventory-statuses/${state.inventoryStatus.uuid}`, {
                name: state.inventoryStatus.name,
                color: state.inventoryStatus.color,
                type: state.inventoryStatus.type,
                order: state.inventoryStatus.order
            }).then((response) => {
                commit('SET_INVENTORY_STATUS', new InventoryStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async delete({ commit, state }) {
            let responseData = null;

            await httpClient.delete(`/management/inventory-statuses/${state.inventoryStatus.uuid}`).then(() => {
                commit('SET_INVENTORY_STATUS', new InventoryStatus());
                responseData = [];
            });
            
            return responseData;
        }
    },
    mutations: {
        SET_INVENTORY_STATUS(state, inventoryStatus) {
            state.inventoryStatus = inventoryStatus;
        }
    }
}