import Model from '../../../../../../resources/js/infrastructure/Model'
export default class Category extends Model {
    id: number | null
    uuid: string | null
    name: string
    names: object
    parentUuid: string | null
    parent: Category | null
    createdAt: Date | null
    updatedAt: Date | null

    constructor(data: any = {}) {
        super()
        this.id = data?.id || null
        this.uuid = data?.uuid || null
        this.name = data?.name || ''
        this.names = data?.names || {}
        this.parentUuid = data?.parent_uuid || null
        this.parent = data?.parent ? new Category(data.parent) : null
        this.createdAt = data?.created_at ? new Date(data.created_at) : null
        this.updatedAt = data?.updated_at ? new Date(data.updated_at) : null
    }
}