import Warehouse from "../models/Warehouse";
import { findWarehouse, updateWarehouse, deleteWarehouse } from "../api/warehouse";
export default {
    namespaced: true,
    state: {
        warehouse: new Warehouse()
    },
    getters: {
        warehouse(state) {
            return state.warehouse;
        }
    },
    actions: {
        async fetch({ commit }, { uuid }) {
            let warehouseData = await findWarehouse(uuid);

            if (warehouseData) {
                let warehouse = new Warehouse(warehouseData);

                commit('SET_WAREHOUSE', warehouse);
            }
        },
        async update({ dispatch, state, commit }) {
            let request = await dispatch('makeRequest'),
                warehouse = await updateWarehouse(state.warehouse.uuid, request);
            
            commit('SET_WAREHOUSE', warehouse);

            return warehouse;
        },
        async delete({ state}) {
            return deleteWarehouse(state.warehouse.uuid);
        },
        makeRequest({ state }) {
            let request = {
                name: state.warehouse.name
            }

            return request;
        }
    },
    mutations: {
        SET_WAREHOUSE(state, warehouse) {
            state.warehouse = warehouse;
        }
    }
}