import Model from '../../../../../../resources/js/infrastructure/Model'
import FilterItem from './FilterItem'
import ModelCollection from '../../../../../../resources/js/infrastructure/Collection/ModelCollection'
export default class Filter extends Model {
    id: number | null
    uuid: string | null
    items: ModelCollection<FilterItem>
    name: string
    names: object
    createdAt: Date | null
    updatedAt: Date | null

    constructor(data: any = {}) {
        super()
        this.id = data?.id || null
        this.uuid = data?.uuid || null
        this.items = data?.items ? new ModelCollection(data.items.map((item: any) => new FilterItem(item))) : new ModelCollection()
        this.name = data?.name || ''
        this.names = data?.names || {}
        this.createdAt = data?.created_at ? new Date(data.created_at) : null
        this.updatedAt = data?.updated_at ? new Date(data.updated_at) : null
    }
}