import { httpClient } from "../../../../../../resources/js/services/HttpClient";
import BankDetail from "../models/BankDetail";

export default {
    namespaced: true,
    state: {
        bankDetail: new BankDetail(),
    },
    getters: {
        bankDetail(state) {
            return state.bankDetail;
        }
    },
    actions: {
        async fetch({ commit }, uuid) {
            await httpClient.get(`/system/bank-details/${uuid}`).then((response) => {
                commit('SET_BANK_DETAILS', new BankDetail(response.data.data));
            });
        },
        async create({ commit, state }, additionalData = {}) {
            let responseData = null,
                request = {
                    ...additionalData,
                    name: state.bankDetail.name,
                    name_in_document: state.bankDetail.nameInDocument,
                    code: state.bankDetail.code,
                    iban: state.bankDetail.iban,
                    bank_name: state.bankDetail.bankName,
                    address: state.bankDetail.address,
                    phone: state.bankDetail.phone,
                    email: state.bankDetail.email,
                }
                
            await httpClient.post('/system/bank-details', request).then((response) => {
                responseData = response.data
            });

            return responseData;
        },
        async update({ commit, state }) {
            let responseData = null;

            await httpClient.patch(`/system/bank-details/${state.bankDetail.uuid}`, {
                name: state.bankDetail.name,
                name_in_document: state.bankDetail.nameInDocument,
                code: state.bankDetail.code,
                iban: state.bankDetail.iban,
                bank_name: state.bankDetail.bankName,
                address: state.bankDetail.address,
                phone: state.bankDetail.phone,
                email: state.bankDetail.email,
            }).then((response) => {
                responseData = response.data
            });

            return responseData;
        },
        async delete({ commit, state }) {
            let responseData = null;

            await httpClient.delete(`/system/bank-details/${state.bankDetail.uuid}`).then((response) => {
                responseData = response.data
            });

            return responseData;
        }
    },
    mutations: {
        SET_BANK_DETAILS(state, item) {
            state.bankDetail = item;
        }
    }
}
