import { httpClient } from "../../../../../../resources/js/services/HttpClient";

export async function findSupplier(uuid: string) {
    let response = await httpClient.get(`/management/suppliers/${uuid}`).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function createSupplier(params: object) {
    let response = await httpClient.post(`/management/suppliers`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function updateSupplier(uuid: string, params: object) {
    let response = await httpClient.patch(`/management/suppliers/${uuid}`, params).then((response) => {
        return response.data
    });

    return response.data ?? null;
}

export async function deleteSupplier(uuid: string) {
    let response = await httpClient.delete(`/management/suppliers/${uuid}`).then((response) => {
        return response.data
    });

    return response;
}