import Model from "../../../../../../resources/js/infrastructure/Model";
export default class CustomField extends Model
{
    id: bigint | null
    uuid: string | null
    model: string
    type: string
    title: string
    description: string
    defaultValue: string | null
    required: boolean
    additional: object
    backgroundTasks: []

    constructor(customField) {
        super();

        this.id = customField?.id ?? null;
        this.uuid = customField?.uuid ?? null;
        this.model = customField?.model ?? '';
        this.type = customField?.type ?? '';
        this.title = customField?.title ?? '';
        this.description = customField?.description ?? '';
        this.defaultValue = customField?.default_value ?? null;
        this.required = customField?.required ?? false;
        this.additional = customField?.additional ?? {};
        this.backgroundTasks = customField?.background_tasks ?? [];
    }
}
