import { httpClient } from '../../../../../../resources/js/services/HttpClient';
import ArrivalDeclarationStatus from '../models/ArrivalDeclarationStatus';
export default {
    namespaced: true,
    state: {
        arrivalDeclarationStatus: new ArrivalDeclarationStatus()
    },
    getters: {
        arrivalDeclarationStatus(state) {
            return state.arrivalDeclarationStatus;
        }
    },
    actions: {
        async fetch({ commit }, uuid) {
            await httpClient.get(`/management/arrival-declaration-statuses/${uuid}`).then((response) => {
                commit('SET_ARRIVAL_DECLARATION_STATUS', new ArrivalDeclarationStatus(response.data.data));
            });
        },
        async create({ commit, state }) {
            let responseData = null;

            await httpClient.post('/management/arrival-declaration-statuses', {
                name: state.arrivalDeclarationStatus.name,
                color: state.arrivalDeclarationStatus.color,
                type: state.arrivalDeclarationStatus.type,
                order: state.arrivalDeclarationStatus.order
            }).then((response) => {
                commit('SET_ARRIVAL_DECLARATION_STATUS', new ArrivalDeclarationStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async update({ commit, state }) {
            let responseData = null;

            await httpClient.put(`/management/arrival-declaration-statuses/${state.arrivalDeclarationStatus.uuid}`, {
                name: state.arrivalDeclarationStatus.name,
                color: state.arrivalDeclarationStatus.color,
                type: state.arrivalDeclarationStatus.type,
                order: state.arrivalDeclarationStatus.order
            }).then((response) => {
                commit('SET_ARRIVAL_DECLARATION_STATUS', new ArrivalDeclarationStatus(response.data.data)),
                responseData = response.data.data
            });

            return responseData;
        },
        async delete({ commit, state }) {
            let responseData = null;

            await httpClient.delete(`/management/arrival-declaration-statuses/${state.arrivalDeclarationStatus.uuid}`).then(() => {
                commit('SET_ARRIVAL_DECLARATION_STATUS', new ArrivalDeclarationStatus());
                responseData = [];
            });
            
            return responseData;
        }
    },
    mutations: {
        SET_ARRIVAL_DECLARATION_STATUS(state, arrivalDeclarationStatus) {
            state.arrivalDeclarationStatus = arrivalDeclarationStatus;
        }
    }
}