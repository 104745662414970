import Model from '../../../../../../resources/js/infrastructure/Model'
import Filter from './Filter'
export default class FilterItem extends Model {
    id: number | null
    uuid: string | null
    filterId: number | null
    filter: Filter
    name: string
    names: object
    createdAt: Date | null
    updatedAt: Date | null

    constructor(data: any = {}) {
        super()
        this.id = data?.id || null
        this.uuid = data?.uuid || null
        this.filterId = data?.filter_id || null
        this.filter = data?.filter
            ? new Filter(data.filter)
            : new Filter()
        this.name = data?.name || ''
        this.names = data?.names || {}
        this.createdAt = data?.created_at ? new Date(data.created_at) : null
        this.updatedAt = data?.updated_at ? new Date(data.updated_at) : null
    }
}