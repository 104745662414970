import { httpClient } from "../../services/HttpClient";
import PriceTemplate from "../models/PriceTemplate";

const BASE_URL = "/customization/price-templates";

export class PriceTemplateApi {

    private static getUrl(endpoint: string = ''): string {
        return `${BASE_URL}${endpoint}`;
    }

    static async findAll(request?: Record<string, any>): Promise<PriceTemplate[]> {
        const response = await httpClient.get(this.getUrl(), request)

        return response.data.data.map((item: any) => new PriceTemplate(item));
    }

    static async create(request: Record<string, any>): Promise<PriceTemplate> {
        const response = await httpClient.post(this.getUrl(), request)

        return new PriceTemplate(response.data.data)
    }

    static async edit(request: Record<string, any>, priceTemplateUuid: string): Promise<PriceTemplate> {
        const response = await httpClient.patch(this.getUrl(`/${priceTemplateUuid}`), request)

        return new PriceTemplate(response.data.data)
    }

    static async remove(priceTemplateUuid: string): Promise<void> {
        return await httpClient.delete(this.getUrl(`/${priceTemplateUuid}`))
    }
}